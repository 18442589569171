import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'
import { createSelector } from '@rematch/select'

import { RootModel } from '@/models'
import { api } from '@/utilities/api'
import {
  KdsCreateBaseOrderContract,
  KdsDisplayOrderContract,
  KdsOrderStatus,
  KdsPaymentStatus,
  SendOrderInvoiceContract,
} from '@/types/api'
import { RootState } from '@/utilities/store'
import { selectLocation } from './locations'
import { getFilteredOrders } from '@/utilities/functions'

interface OrdersState {
  orders: KdsDisplayOrderContract[]
  kitchenOrders: KdsDisplayOrderContract[]
}

const initialState: OrdersState = {
  orders: [],
  kitchenOrders: [],
}

export const orders = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setOrders(state, orders: KdsDisplayOrderContract[]) {
      return { ...state, orders }
    },
    addOrder(state, order: KdsDisplayOrderContract) {
      return { ...state, orders: [...state.orders, order] }
    },
    setKitchenOrders(state, kitchenOrders: KdsDisplayOrderContract[]) {
      return { ...state, kitchenOrders }
    },
    addKitchenOrder(state, kitchenOrder: KdsDisplayOrderContract) {
      return { ...state, kitchenOrders: [...state.kitchenOrders, kitchenOrder] }
    },
    updateOrder(state, { id, data }: { id: string; data: KdsDisplayOrderContract }) {
      return {
        ...state,
        orders: state.orders.map((order) => (order.id === id ? { ...order, ...data } : order)),
      }
    },
    updateKitchenOrder(state, { id, data }: { id: string; data: KdsDisplayOrderContract }) {
      return {
        ...state,
        kitchenOrders: state.kitchenOrders.map((kitchenOrder) =>
          kitchenOrder.id === id ? { ...kitchenOrder, ...data } : kitchenOrder,
        ),
      }
    },
    makeOrderReady(state, { key, order }: { key: 'orders' | 'kitchenOrders'; order: KdsDisplayOrderContract }) {
      const filteredOrders = state[key].filter((o) => o.id !== order.id)

      return {
        ...state,
        [key]: [...filteredOrders, order],
      }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getOrders(locationId: string) {
      const { data }: AxiosResponse<KdsDisplayOrderContract[]> = await api.get(`/orders/active/${locationId}`)

      dispatch.orders.setOrders(data)

      return data
    },
    async getKitchenOrders(payload: { locationId: string; onlyData?: boolean }) {
      const { data }: AxiosResponse<KdsDisplayOrderContract[]> = await api.get(`/orders/kitchen/${payload.locationId}`)

      if (!payload.onlyData) {
        dispatch.orders.setKitchenOrders(data)
      }

      return data
    },
    async markOrderReady(orderId: string) {
      const res: AxiosResponse<KdsDisplayOrderContract> = await api.post(`/orders/${orderId}/markReady`)

      dispatch.orders.makeOrderReady({ key: 'orders', order: res.data })
    },
    async markKitchenOrderReady(orderId: string) {
      const res: AxiosResponse<KdsDisplayOrderContract> = await api.post(`/orders/${orderId}/markReady`)

      dispatch.orders.makeOrderReady({ key: 'kitchenOrders', order: res.data })
    },
    async markOrderPaid(orderId: string) {
      await api.post(`/orders/${orderId}/markPaid`)

      dispatch.orders.updateOrder({ id: orderId, data: { paymentStatus: KdsPaymentStatus.Paid } })
    },
    async markKitchenOrderPaid(orderId: string) {
      await api.post(`/orders/${orderId}/markPaid`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: { paymentStatus: KdsPaymentStatus.Paid } })
    },
    async markOrderFinished(orderId: string) {
      const res: AxiosResponse<KdsDisplayOrderContract> = await api.post(`/orders/${orderId}/markFinished`)

      dispatch.orders.updateOrder({ id: orderId, data: res.data })
    },
    async markKitchenOrderFinished(orderId: string) {
      const res: AxiosResponse<KdsDisplayOrderContract> = await api.post(`/orders/${orderId}/markFinished`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: res.data })
    },
    async markKitchenReady(orderId: string) {
      await api.post(`/orders/${orderId}/kitchen/ready`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: { kitchenStatus: KdsOrderStatus.Ready } })
    },
    async markKitchenOrderPrinted(orderId: string) {
      await api.post(`/orders/${orderId}/markPrinted`)
    },
    async createBaseOrder(payload: KdsCreateBaseOrderContract) {
      const { data }: AxiosResponse<KdsDisplayOrderContract> = await api.post('/orders/createBase', payload)

      dispatch.orders.addOrder(data)

      dispatch.orders.addKitchenOrder(data)
    },
    async sendInvoice(payload: SendOrderInvoiceContract) {
      await api.post('/orders/sendInvoice', payload)
    },
  }),
})

export const selectOrdersObj = createSelector(
  ({ orders }: RootState) => orders,
  ({ orders }) => {
    return orders.reduce<{ [orderId: string]: KdsDisplayOrderContract }>((obj, order) => {
      obj[order.id!] = order

      return obj
    }, {})
  },
)

export const selectKitchenOrdersObj = createSelector(
  ({ orders }: RootState) => orders,
  ({ kitchenOrders }) => {
    return kitchenOrders.reduce<{ [orderId: string]: KdsDisplayOrderContract }>((obj, kitchenOrder) => {
      obj[kitchenOrder.id!] = kitchenOrder

      return obj
    }, {})
  },
)

export const selectOrders = createSelector(
  ({ orders }: RootState) => orders,
  ({ orders }) => {
    return orders.reduce<{ created: KdsDisplayOrderContract[]; ready: KdsDisplayOrderContract[] }>(
      (obj, order) => {
        switch (order.status) {
          case KdsOrderStatus.Created:
            obj.created.push(order)

            break
          case KdsOrderStatus.Ready:
            obj.ready.push(order)

            break
          default:
            break
        }

        return obj
      },
      { created: [], ready: [] },
    )
  },
)

export const selectFilteredOrders = createSelector(
  ({ orders }: RootState) => orders,
  selectLocation,
  selectOrders,
  (_, location, orders) => {
    return {
      created: getFilteredOrders(location, orders.created),
      ready: getFilteredOrders(location, orders.ready),
    }
  },
)

export const selectKitchenOrders = createSelector(
  ({ orders }: RootState) => orders,
  ({ kitchenOrders }) => {
    return kitchenOrders.reduce<{ created: KdsDisplayOrderContract[]; ready: KdsDisplayOrderContract[] }>(
      (obj, kitchenOrder) => {
        switch (kitchenOrder.status) {
          case KdsOrderStatus.Created:
            obj.created.push(kitchenOrder)

            break
          case KdsOrderStatus.Ready:
            obj.ready.push(kitchenOrder)

            break
          default:
            break
        }

        return obj
      },
      { created: [], ready: [] },
    )
  },
)

export const selectFilteredKitchenOrders = createSelector(
  ({ orders }: RootState) => orders,
  selectLocation,
  selectKitchenOrders,
  (_, location, kitchenOrders) => {
    return {
      created: getFilteredOrders(location, kitchenOrders.created),
      ready: getFilteredOrders(location, kitchenOrders.ready),
    }
  },
)
