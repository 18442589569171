import { Fragment, MouseEventHandler } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { Box, Typography } from '@mui/material'

import { KdsDisplayOrderContract, KdsOrderStatus, KdsPaymentStatus } from '@/types/api'
import { ReceiptButton } from '@/components'

interface OrderProps {
  order: KdsDisplayOrderContract
  disabled?: boolean
  createdOrderBackgroundColor?: string
  readyOrderBackgroundColor?: string
  enableEmailButton?: boolean
  onOrderClick?: (orderId: string) => void
  onOrderReceiptClick?: (orderId: string) => void
}

interface MainContainerProps {
  backgroundColor?: string
  opacity?: number
}

interface OrderItemProps {
  isOption?: boolean
}

const MainContainer = styled.button<MainContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.5rem;
  overflow: hidden;
  border: 2px solid ${({ backgroundColor }) => backgroundColor};
  display: grid;
  grid-template-rows: auto 1fr auto;
  opacity: ${({ opacity }) => opacity};
  width: 100%;
  height: 100%;
  overflow-wrap: anywhere;

  :enabled {
    cursor: pointer;
  }
`

const HeaderContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const HeaderText = styled(Typography as AnyStyledComponent).attrs({
  variant: 'h6',
})`
  color: #ffffff;
`

const FooterContainer = styled.div`
  padding: 0 0.5rem;
  display: flex;
  column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  height: 2.25rem;
`

const FooterText = styled(Typography as AnyStyledComponent).attrs({
  variant: 'subtitle1',
  fontWeight: 500,
})`
  color: #ffffff;
`

const OrderContainer = styled.div`
  background-color: #ffffff;
  padding: 0.5rem;
  display: grid;
  row-gap: 0.5rem;
`

const OrderItem = styled(Typography as AnyStyledComponent).attrs<OrderItemProps>(({ isOption }) => ({
  variant: isOption ? 'body2' : 'subtitle2',
}))<OrderItemProps>`
  padding-left: ${({ isOption }) => (isOption ? 0.5 : 0)}rem;
`

const Comment = styled(Typography as AnyStyledComponent).attrs({
  variant: 'subtitle2',
})`
  white-space: pre-wrap;
`

const Order = ({
  order,
  disabled,
  createdOrderBackgroundColor,
  readyOrderBackgroundColor,
  enableEmailButton,
  onOrderClick,
  onOrderReceiptClick,
}: OrderProps) => {
  const getBackgroundColor = () => {
    switch (order.kitchenStatus) {
      case KdsOrderStatus.Created:
        return createdOrderBackgroundColor || '#ffa500'
      case KdsOrderStatus.Ready:
        return readyOrderBackgroundColor || '#008000'
      default:
        return
    }
  }

  const handleClick = () => {
    onOrderClick?.(order.id!)
  }

  const handleOrderReceiptClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    onOrderReceiptClick?.(order.id!)
  }

  return (
    <MainContainer
      backgroundColor={getBackgroundColor()}
      opacity={order.paymentStatus === KdsPaymentStatus.Paid ? 1 : 0.5}
      disabled={disabled}
      onClick={handleClick}
    >
      <HeaderContainer>
        <HeaderText>{order.type}</HeaderText>

        {!!order.table && <HeaderText>{order.table}</HeaderText>}
      </HeaderContainer>

      <OrderContainer>
        {!!order.orderProducts?.length && (
          <div>
            {order.orderProducts.map((orderProduct) => (
              <Fragment key={orderProduct.id}>
                <OrderItem>{`${orderProduct.count}x ${orderProduct.title}`}</OrderItem>

                {orderProduct.options?.map((option) => (
                  <OrderItem key={option.id} isOption>{`${option.count}x ${option.title}`}</OrderItem>
                ))}
              </Fragment>
            ))}
          </div>
        )}

        <Box alignSelf="end">
          <Comment>{`Order: #${order.number}`}</Comment>

          {!!order.guestIdentification && <Comment>{`Name: ${order.guestIdentification}`}</Comment>}

          {!!order.commentToKitchen && <Comment>{`Comment: ${order.commentToKitchen}`}</Comment>}
        </Box>
      </OrderContainer>

      <FooterContainer>
        <div>
          {!!enableEmailButton && order.paymentStatus === KdsPaymentStatus.Paid && (
            <ReceiptButton edge="start" disabled={disabled} onClick={handleOrderReceiptClick} />
          )}
        </div>

        <FooterText>{order.paymentStatus}</FooterText>
      </FooterContainer>
    </MainContainer>
  )
}

export default Order
