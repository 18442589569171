import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import Orders from '@/pages/Orders'
import Kitchen from '@/pages/Kitchen'
import Login from '@/pages/Login'
import { PageLayout, ProtectedRoutes, Spinner } from '@/components'
import { useWindowSizes, WindowSizes } from '@/utilities/hooks'
import { Dispatch, RootState } from '@/utilities/store'
import { minutesToMs, setSentryUser, printOrders } from '@/utilities/functions'

const router = createBrowserRouter([
  {
    element: <PageLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: '/:locationId/orders',
            element: <Orders />,
          },
          {
            path: '/:locationId/kitchen',
            element: <Kitchen />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
])

interface SpinnerContainerProps {
  sizes: WindowSizes
}

const SpinnerContainer = styled.div<SpinnerContainerProps>`
  height: ${({ sizes }) => sizes.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Router = () => {
  const [loading, setLoading] = useState<boolean>(true)

  const token = useSelector((state: RootState) => state.authentication.token)
  const { soundEnabled, printing, printerHasError, printerIpAddress } = useSelector((state: RootState) => state.app)

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const getUser = async () => {
    try {
      const [authUser] = await Promise.all([dispatch.authentication.getUser(), dispatch.locations.getLocations()])

      setSentryUser({ id: authUser.id })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!token) {
      setLoading(false)
      return
    }

    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!token) {
      return
    }

    const interval = setInterval(getUser, minutesToMs(10))

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (!soundEnabled || window.ReactNativeWebView) {
      return
    }

    dispatch.app.setSoundEnabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!printerIpAddress || printing || printerHasError) {
      return
    }

    const interval = setInterval(printOrders, 5000)

    return () => clearInterval(interval)
  }, [printerHasError, printerIpAddress, printing])

  useEffect(() => {
    if (!printerHasError) {
      return
    }

    const timeout = setTimeout(() => dispatch.app.setPrinterHasError(false), 5000)

    return () => clearTimeout(timeout)
  }, [dispatch.app, printerHasError])

  if (loading) {
    return (
      <SpinnerContainer sizes={sizes}>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return <RouterProvider router={router} />
}

export default Router
