import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getFilteredOrders, getWindowSizes, playNewOrderSound } from '@/utilities/functions'
import { selectFilteredKitchenOrders, selectFilteredOrders } from '@/models/orders'
import { RootState, Dispatch } from '@/utilities/store'
import { selectLocation } from '@/models/locations'

export interface WindowSizes {
  width: number
  height: number
}

export const useWindowSizes = () => {
  const [sizes, setSizes] = useState<WindowSizes>(getWindowSizes())

  const handleResize = () => {
    setSizes(getWindowSizes())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return sizes
}

export const useOrdersFetch = ({
  locationId,
  playOrdersSound,
  playKitchenOrdersSound,
}: {
  locationId?: string
  playOrdersSound?: boolean
  playKitchenOrdersSound?: boolean
}) => {
  const [initLoading, setInitLoading] = useState<boolean>(true)

  const { soundEnabled } = useSelector((state: RootState) => state.app)

  const orders = useSelector((state: RootState) => selectFilteredOrders(state, locationId))
  const kitchenOrders = useSelector((state: RootState) => selectFilteredKitchenOrders(state, locationId))
  const location = useSelector((state: RootState) => selectLocation(state, locationId))

  const dispatch = useDispatch<Dispatch>()

  const previousOrdersLength = useRef<number>(0)
  const previousKitchenOrdersLength = useRef<number>(0)

  const getOrders = useCallback(async () => {
    if (!locationId) {
      return
    }

    try {
      return await Promise.all([
        dispatch.orders.getOrders(locationId),
        dispatch.orders.getKitchenOrders({ locationId }),
      ])
    } catch (error) {
      console.error(error)
    }
  }, [dispatch.orders, locationId])

  const init = useCallback(async () => {
    setInitLoading(true)

    const res = await getOrders()
    if (!res) {
      setInitLoading(false)
      return
    }

    const [orders, kitchenOrders] = res

    previousOrdersLength.current = getFilteredOrders(location, orders).length
    previousKitchenOrdersLength.current = getFilteredOrders(location, kitchenOrders).length

    setInitLoading(false)
  }, [getOrders, location])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (initLoading) {
      return
    }

    const interval = setInterval(() => {
      getOrders()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [getOrders, initLoading])

  useEffect(() => {
    if (initLoading) {
      return
    }

    if (playOrdersSound && soundEnabled && orders.created.length > previousOrdersLength.current) {
      playNewOrderSound()
    }

    previousOrdersLength.current = orders.created.length
  }, [initLoading, orders.created.length, playOrdersSound, soundEnabled])

  useEffect(() => {
    if (initLoading) {
      return
    }

    if (playKitchenOrdersSound && soundEnabled && kitchenOrders.created.length > previousKitchenOrdersLength.current) {
      playNewOrderSound()
    }

    previousKitchenOrdersLength.current = kitchenOrders.created.length
  }, [initLoading, kitchenOrders.created.length, playKitchenOrdersSound, soundEnabled])

  return { initLoading }
}
