import { MouseEventHandler } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { Typography, Box } from '@mui/material'

import { KdsDisplayOrderContract, KdsOrderStatus, KdsPaymentStatus } from '@/types/api'
import { ReceiptButton } from '@/components'

interface OrderProps {
  order: KdsDisplayOrderContract
  disabled?: boolean
  createdOrderBackgroundColor?: string
  readyOrderBackgroundColor?: string
  enableEmailButton?: boolean
  onOrderClick?: (orderId: string) => void
  onOrderReceiptClick?: (orderId: string) => void
}

interface MainContainerProps {
  backgroundColor?: string
  opacity?: number
}

const MainContainer = styled.button<MainContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  opacity: ${({ opacity }) => opacity};
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;

  :enabled {
    cursor: pointer;
  }
`

const Number = styled(Typography as AnyStyledComponent)`
  color: #ffffff;
`

const PaymentStatusText = styled(Typography as AnyStyledComponent).attrs({
  variant: 'body2',
  fontWeight: 500,
  align: 'right',
})`
  color: #ffffff;
`

const Order = ({
  order,
  disabled,
  createdOrderBackgroundColor,
  readyOrderBackgroundColor,
  enableEmailButton,
  onOrderClick,
  onOrderReceiptClick,
}: OrderProps) => {
  const getBackgroundColor = () => {
    switch (order.status) {
      case KdsOrderStatus.Created:
        return createdOrderBackgroundColor || '#ffa500'
      case KdsOrderStatus.Ready:
        return readyOrderBackgroundColor || '#008000'
      default:
        return
    }
  }

  const handleClick = () => {
    onOrderClick?.(order.id!)
  }

  const handleOrderReceiptClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    onOrderReceiptClick?.(order.id!)
  }

  return (
    <MainContainer
      backgroundColor={getBackgroundColor()}
      opacity={order.paymentStatus === KdsPaymentStatus.Paid ? 1 : 0.5}
      disabled={disabled}
      onClick={handleClick}
    >
      {!!enableEmailButton && order.paymentStatus === KdsPaymentStatus.Paid && (
        <Box position="absolute" left={0} bottom={0}>
          <ReceiptButton disabled={disabled} onClick={handleOrderReceiptClick} />
        </Box>
      )}

      <Number variant="h4" align="center">
        {order.number}
      </Number>

      <Box px={1}>
        <PaymentStatusText>{order.paymentStatus}</PaymentStatusText>
      </Box>
    </MainContainer>
  )
}

export default Order
